import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Picker from './Picker'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { TimePicker } from 'antd';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import firebase from "./firebase.js";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit">
        Envision Fitness
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
}));



export default function SignUp(props) {
  const {setContact, type, setType} = props;
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState("");
  const [time, setTime] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    console.log(type);
    setType(event.target.value);
  };

  function onChange(time, timeString) {
    console.log(time, timeString);
  }

  function handleDayChange(selectedDay, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    setSelectedDate(input.value);
  }
  console.log(type);
  function IsValidEmail(email) {
    //Check minimum valid length of an Email.
    if (email.length <= 2) {
        return false;
    }
    //If whether email has @ character.
    if (email.indexOf("@") == -1) {
        return false;
    }

    var parts = email.split("@");
    var dot = parts[1].indexOf(".");
    var len = parts[1].length;
    var dotSplits = parts[1].split(".");
    var dotCount = dotSplits.length - 1;


    //Check whether Dot is present, and that too minimum 1 character after @.
    if (dot == -1 || dot < 2 || dotCount > 2) {
        return false;
    }

    //Check whether Dot is not the last character and dots are not repeated.
    for (var i = 0; i < dotSplits.length; i++) {
        if (dotSplits[i].length == 0) {
            return false;
        }
    }

    return true;
};
  function submit(){
    let now = new Date()
    if (!IsValidEmail(email)) {
      alert("Please enter a valid Email"); 
      return;
    }
    let data = {type: type, firstName: firstName, lastName: lastName , email: email, description: desc, selectedDate: selectedDate, time: time}
    firebase
    .database()
    .ref("messages/"+firstName+" "+lastName +"--"+ now.toString())
    .set(data);
    setContact(false)
  }
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Contact
        </Typography>
        <Typography component="p" variant="p">
          Enter some info about you and request a date and time.
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={(value) => setFirstName(value.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={(value) => setLastName(value.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(value) => setEmail(value.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
            <FormLabel component="legend">Type</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={type} onChange={handleChange}>
      <FormControlLabel value='1-1 Consultation' control={<Radio />} label="1-1 Consultation" />
        <FormControlLabel value='1-1 Training' control={<Radio />} label="1-1 Training" />
        <FormControlLabel value='Partner Training' control={<Radio />} label="Partner Training" />
        <FormControlLabel value='Small Group Training' control={<Radio />} label="Small Group Training" />
        <FormControlLabel value='Other' control={<Radio />} label="Other" />
      </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                multiline={true}
                name="description"
                label="Description"
                id="description"
                autoComplete="current-password"
                onChange={(value) => setDesc(value.target.value)}
              />
            </Grid>
<Grid item xs={12} sm={5}><FormLabel component="legend">Date</FormLabel>
        <DayPickerInput  
        onDayChange={handleDayChange}
        style={{marginBottom:30, fontSize:30, height: 25}}  
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={`${formatDate(new Date())}`}/>
        </Grid><Grid item xs={12} sm={2}><FormLabel component="legend">Time</FormLabel>
        <TextField
        style={{marginTop:7}}
        id="time"
        label=""
        type="time"
        defaultValue={new Date()}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        onChange={(value) => setTime(value.target.value)}
      /></Grid></Grid>
          <Button
            onClick={() => {submit(); }}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send
          </Button>
          <Button
            onClick={() => setContact(false)}
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            CANCEL
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
            
            </Grid>
          </Grid>

      </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}