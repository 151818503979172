import firebase from 'firebase';
const config = {
  apiKey: "AIzaSyBFW4hWK-3e-yh57NnKZBUrspmIeKXiZ8A",
  authDomain: "susy-yoga.firebaseapp.com",
  projectId: "susy-yoga",
  storageBucket: "susy-yoga.appspot.com",
  messagingSenderId: "142955953788",
  appId: "1:142955953788:web:1620b3abbcb1381d9c53f9",
  databaseURL: "https://susy-yoga-default-rtdb.firebaseio.com/"
};
  firebase.initializeApp(config);
  export default firebase;