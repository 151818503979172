import React, {useState, useRef} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import * as yoga from "./yoga.jpeg"
import * as decor from "./decor.jpeg"
import * as seq1 from "./seq1.pdf"
import * as seq2 from "./seq2.pdf"
import * as susy from "./susy.jpeg"
// import * as ysports from "./syoga.pdf"
// import * as wyoga from "./wyoga.pdf"
import * as zoom from "./ZoomSetup.pdf"
import Pricing from "./Pricing"
import SignUp from './SignUp';
import Gallery from './Gallery';
import Post from './Post';
import Slider from 'infinite-react-carousel';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import SingleLineGridList from "./SingleLineGridList"
import MyAppBar from "./MyAppBar"
import {
  MDBContainer,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBTestimonial,
  MDBAvatar,
  MDBIcon
} from 'mdbreact';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Susy Yoga
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: 'white',
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    marginTop: 100,
    backgroundColor: "#edf4ff",
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3];

export default function Album() {
  const classes = useStyles();
  const [contact, setContact] = useState(false);
  const [type, setType] = useState("1-1 Consultation");
  const about = useRef(null)
  const schedule = useRef(null)
  const pay = useRef(null)
  const resources = useRef(null)
  const props = useRef(null)
  const gallery = useRef(null)
  let navMap = {
    about: about,
    schedule: schedule,
    pay: pay,
    resources: resources,
    props: props,
    gallery: gallery
  }
  function executeScroll(value) {
    let ref = navMap[value]
    ref.current.scrollIntoView()
    window.scrollBy(0, -70)
  }
  
  if (contact){
    return (
      <div>
        <MyAppBar executeScroll={executeScroll}/>
      <SignUp setContact={setContact} type={type} setType={setType}/>
      </div>
      
    )
  }
  const settings =  {
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000,
    centerMode: true,
    shift: 100,
    arrows: true,
  };
  let testimonialFontSize = 25
  if (window.innerWidth < 600) testimonialFontSize = 15;

  return (
    <Router>
        <Switch>
        <Route path="/makeblogpost">
            <Post />
          </Route>
          <Route path="/">
          <React.Fragment>
      <MyAppBar executeScroll={executeScroll}/>
      
      <main>
        {/* Hero unit */}
        <div style={{height: 350, background: `url(${yoga}) left`}} className={classes.heroContent}>
          <Container maxWidth="sm">
          <Typography component="h1" variant="h2" align="center" style={{color:'purple', fontWeight:"normal", backgroundColor: "rgba(255, 255, 255, 0.5)", borderRadius: 10}} gutterBottom>
              Yoga for Health
            </Typography>
            <Typography component="h1" variant="h2" align="center" style={{color:'purple', fontWeight:"bolder", backgroundColor: "rgba(255, 255, 255, 0.5)", borderRadius: 10}} gutterBottom>
              {"Yoga for Healing"}
            </Typography>
            <img src={susy} style={{display: "block", marginLeft: "auto", marginRight: "auto", maxHeight: 300, height: "50vw", borderRadius: 35, boxShadow: '0 0 30px #000000'}}/>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  {/* <Button onClick={() => setContact(true)} variant="contained" align="right" color="primary">
                    Schedule a free Consultation
                  </Button> */}
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <div style={{height:100}}></div>
        <div ref={about} style={{padding: 20}}><Typography  component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          About
        </Typography></div>
        <div style={{borderRadius: 20, margin: "auto", maxWidth:900, backgroundColor: "#edf4ff", boxShadow: '0 0 30px #cccccc', color:"#545454"}} >
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30, paddingTop: 30}} component="p">I began my study of yoga more than 40 years ago and have taught at the Yoga Center of Marin and elsewhere for over 20 years. My yoga certification is from the California Institute of Integral Studies. My practice has been fueled by numerous trips to India where I studied with the Iyengars and followers of Sri Aurobindo.  I have also taught many, many of the physicians and staff at the Aravind Eye Care Systems (the largest eye care system in the world, where compassionate service is the guiding mission and all are treated without ability to pay) in southern India. I taught middle school science for 20 years. For 20 years I studied and mentored with Angeles Arrien, anthropologist and developer of the universal principles of communication, collective wisdom and collaboration.</Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Asana practice creates a deep experience of balance, flexibility, strength, and self awareness.  It is a transformational practice, one that is dearly needed in these transformational times.</Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Finding the source of your well-being and healing power does not depend on the latest diet book or exercise fad.  Rather it is in engaging your inner realm and finding the wisdom and power inherent within you.  That is where the will and energy to change bad habits and foster constructive behavior reside.  The common pathway toward health and healing is gaining the knowledge and skills to initiate and sustain desired change.</Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 30}} component="p">Mature yoga students and beginners alike will benefit from my holistic perspective, sensitivity and experience.  People with special health and fitness needs are welcome. Come prepared to explore the possibility that yoga has to offer for alignment of mind, body and spirit.</Typography>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
         
        </Container>
      </main>
{/* 
      <div style={{marginTop:100, padding:0, minHeight:600, backgroundColor: "#edf4ff", boxShadow: '0 0 10px #ccc', color:"#545454"}}>
      <div style={{padding: 60}}><Typography  component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          Notes From Students
        </Typography></div>
      
      <Slider {...settings}>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"Loved last week and will try to attend again, when you have it scheduled. Have a great holiday! Thank you for all your wonderful yoga instruction this last year. You have a gift... and I am so happy you share it!"}</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"Yoga is one thing keeping me (semi) grounded this year."}</Typography>
        </div>
      </Slider>
      </div> */}

<div ref={schedule} style={{marginTop:50, padding:30, minHeight:400, backgroundColor: "#edf4ff", boxShadow: '0 0 10px #ccc', color:"#545454"}}>
<Typography  component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          When We Practice
        </Typography>
        <div style={{borderRadius: 20, margin: "auto", maxWidth:900, backgroundColor: "white", boxShadow: '0 0 30px #cccccc', color:"#545454"}} >
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30, paddingTop: 30}} component="p">Classes are online and in person. New students please contact me at <Link href="mailto:susyoga2@gmail.com">susyoga2@gmail.com</Link> before signing up.</Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h4" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Saturday - in person</Typography>

        <Typography variant="h6" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Saturday students sign up through: <Link target="_blank" href="https://marincommunityed.augusoft.net/"> College of Marin Community Education</Link></Typography>

        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Beginners:</Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Winter Session:  Feb 4-Mar 11 (no class Feb 18)  10-11:30a</Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Spring Session:  Apr 1-May 13  10-11:30a</Typography>
<br></br>

        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Intermediate/Advanced:</Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Winter Session:  Feb 4-Mar 11 (no class Feb 18)  8-9:45a</Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Spring Session:  Apr 1-May 13  8-9:45a</Typography>
        <br></br>

        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Summer Session:  TBD</Typography>

        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h4" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Monday - online</Typography>
        <Typography variant="h6" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Monday students sign up through me at: <Link href="mailto:susyoga2@gmail.com">susyoga2@gmail.com</Link></Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Winter/Spring Session:  Feb 5-May 22 (no class Mar 13).</Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 30}} component="p">That is 15 classes. I’m suggesting a $150 donation to a cause of your choosing. 
        <Link onClick={() => executeScroll('pay')}> Pay It Forward</Link> .  Merely let me know where you have sent it.  Or you can send me a check and I’ll send it to the food bank or Marin Community Clinic.  My address is 137 San Marino Dr. San Rafael 94901   </Typography>
        </div>
      
    </div>

    <img src={decor} style={{display: "block", marginTop: 50, marginLeft: "auto", marginRight: "auto", height: 300, borderRadius: 150, boxShadow: '0 0 30px #000000'}}/>

    <div ref={pay} style={{marginTop:50, padding:0, minHeight:400, backgroundColor: "#edf4ff", boxShadow: '0 0 10px #ccc', color:"#545454"}}>
      <Typography style={{paddingTop:30}} component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          Pay It Forward
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">The philosophy behind “Pay It Forward” came from my friends at <Link target="_blank" href={"https://www.servicespace.org/"}>Servicespace</Link> who initiated Karma Kitchen in Berkeley.  They rented a restaurant and the food for the afternoon. Volunteers did the hosting, serving and clean- up. Customers were told upon entrance that their meal had been paid for by the people ahead of them and they could “pay forward” for the next if they wished. Learn more at: <Link target="_blank" href={"http://www.karmakitchen.org"}>http://www.karmakitchen.org</Link>
</Typography>
        <div style={{paddingTop: 60}}><Typography  component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
          How Students Pay It Forward
        </Typography></div>
      
      <Slider {...settings}>
      <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{
        "This is to confirm that GiveIndia Fundraisers has received a total donation of $ 149 from you , which includes $ 142 for ‘Donate food to families struggling with hunger in Covid's second wave’ and $ 7 for your support to GiveIndia."}
        </Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{
        "I have made a donation to the Michael J. Fox foundation.  My family has been ravaged by this disease and  it is much on my mind.  I hope this is agreeable to you.  I am eternally grateful for you teaching."}
        </Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{
          "Thank you Susy for encouraging us to support our community.  Donation to the Marin Food Bank."
        }</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{
          "Donation to Unicef.  Thank you for your selfless service Susy.  The positive karma is rippling around the world."
        }</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{
          "I donated my yoga class fees to Seeds of Peace, a not-for-profit organization that brings together teenagers from conflict regions such as Israeli/Palestinian, Pakistani/Indian, Serbian/Bosnian and trains them to work together to respect each others’ cultures and build more just societies.  Maybe we’ll have world peace someday. . . ."
        }</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{
        "Thank you for continuing to teach and inspire.  I’m so grateful for my yoga classes"
        }</Typography>
        </div>
      <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"Thank you Susy. I am Paying It Forward ($150) to Casa Allegra Community Services, an organization supporting adults with intellectual and other developmental disabilities. CACS provides an array of individually designed services and support around community living, family, daytime activities and employment opportunities. Thank you, Susy \n   "}<a  href="https://www.casaallegra.org/" target="_blank">https://www.casaallegra.org/.</a></Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"Novato has a shop called Sustainable Exchange ('Shop Like There is a Tomorrow - Because This Planet Means The World To Us') and they have a little free pantry outside their front doors. It's on the honor system, and apparently it is empty most mornings, and all throughout the day people add back to it. I have already added some to it and I will be adding more to pay it forward. "}</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"Sent to Marin Humane Society…Downward dog donation Susy, thank you for our Yoga together."}</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"I just made a contribution to the Marin Humane Society - thinking about Harley and all the great animals that are keeping us company during these times. Loved seeing Harley on screen so much in the last class."}</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"First of all, thank you so much for your wonderful teaching. It has been such an inspiration for me, so health-supporting! I also really appreciate the generosity of your pay it forward philosophy. I made a donation in your honor to the Himalayan Cataract Project, as recommended by Nicholas Kristof, and I feel so grateful to be able to support this organization make a huge impact in someone’s life- restoring their sight. Thank you again for your kindness and wisdom. Namaste."}</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"I wanted to let you know I decided to donate to The Street Chaplaincy Program of Marin Interfaith Council.  Their REST program does hot meals for San Rafael homeless people.  I’m also doing a gift bag for a young pre-teen who has been homeless."}</Typography>
        </div>
        <div>
        <Typography variant="h5" align="center" color="textSecondary" style={{padding: testimonialFontSize, fontSize: testimonialFontSize }} component="p">{"Chuck and I have been making 8 bag lunches every Wednesday since March ... for people in Sausalito and those St. Vincent de Paul serves, and there’s no indication that it will become unnecessary any time soon. So much need this year…. Thank you for giving us much needed yoga practice."}</Typography>
        </div>
      </Slider>
      </div>


      <div ref={resources} style={{padding:30, minHeight:400, color:"#545454"}}>
<Typography  component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          Resources
        </Typography>

        <div style={{borderRadius: 20, margin: "auto", maxWidth:900, backgroundColor: "#edf4ff", boxShadow: '0 0 30px #cccccc', color:"#545454"}} >
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href={zoom}>At Home Setup</Link></Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href="http://artofyoga.co.uk/patanjali/invocation-to-patanjali/">Invocation to Patanjali</Link></Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href={seq1}>Sequence for Acquaintance #1</Link></Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href={seq2}>Home Practice Sequence Level 2</Link></Typography>
        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" />
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p">Books</Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href="https://yogabog.com/sites/default/files/files/Iyengar_B_K_S__The_Illustrated_Light_On_Yoga.pdf">Light on Yoga</Link></Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href="https://recovery-wise.com/wp-content/uploads/2020/10/The-Path-To-Holistic-Health-1.pdf">Yoga The Path to Holistic Health</Link></Typography>
        {/* <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href={wyoga}>Yoga a Gem for Women</Link></Typography> */}
        {/* <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30}} component="p"><Link target="_blank" href={ysports}>Yoga for Sports</Link></Typography> */}

        <img style={{marginLeft: "48%"}} src={"https://static.thenounproject.com/png/14177-200.png"} height="35" /> 
        <Typography variant="h5" align="center" color="textSecondary" style={{paddingLeft: 30, paddingRight: 30, paddingBottom: 30}} component="p"><Link target="_blank" href="https://drive.google.com/drive/folders/1T8UT15Gm8C2Mej47nBGGmNVMPlmTS9BP">My Google Drive</Link></Typography>
      </div>  
    </div>

    <div ref={props} style={{marginTop:100, padding: 30, minHeight:400, backgroundColor: "#edf4ff", boxShadow: '0 0 10px #ccc', color:"#545454"}}>
<Typography  component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          Recommended Props
        </Typography>
      <SingleLineGridList />
      <div style={{marginTop: 20, display: 'flex', flexDirection: "row"}}>
        <Typography style={{fontSize: 20}}>Potential Sources:</Typography>
      <Link target="_blank" href="https://www.yogamats.com/" style={{fontSize: 20, marginLeft: 20}}>
      Yoga Mats San Francisco
      </Link>
      <Link target="_blank" href="https://www.huggermugger.com/" style={{fontSize: 20, marginLeft: 20}}>
        Hugger Mugger
      </Link>
      </div>
      
</div>
      <div style={{marginTop: 30}} ref={gallery}>
      <Gallery />
      </div>
      
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Thanks for visting
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Please reach out!
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
          </Route>
          
        </Switch>
    </Router>
    
  );
}