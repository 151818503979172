import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import * as logo from "./logo.png"

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'white',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const toolbar = <AppBar style={{backgroundColor:'white', midWidth:900}} position="sticky">
  <Toolbar style={{backgroundColor:'white', midWidth:900}} >
  <div><img style={{marginLeft: 0, marginTop:5, marginBottom: 10}} src={logo} height="75" /></div>
  </Toolbar>
  

  
  
</AppBar>

export default function MyAppBar(props) {
  const {executeScroll} = props;
  console.log(executeScroll)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => executeScroll('about')}>
        <div style={{color: 'purple'}}>About</div>
      </MenuItem>
      <MenuItem onClick={() => executeScroll('schedule')}>
        <div style={{color: 'purple'}}>Schedule</div>
      </MenuItem>
      <MenuItem onClick={() => executeScroll('pay')}>
        <div style={{color: 'purple'}}>Pay</div>
      </MenuItem>
      <MenuItem onClick={() => executeScroll('resources')}>
        <div style={{color: 'purple'}}>Resources</div>
      </MenuItem>
      <MenuItem onClick={() => executeScroll('props')}>
        <div style={{color: 'purple'}}>Props</div>
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <AppBar position="sticky">
        <Toolbar style={{backgroundColor:'white'}}>
          <img style={{marginLeft: 0, marginTop:5, marginBottom: 10, width: "70%", maxWidth:300}} src={logo} />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button onClick={() => executeScroll('about')} color="inherit">
              <div style={{color: 'purple'}}>About</div>
            </Button>
            <Button onClick={() => executeScroll('schedule')} color="inherit">
              <div style={{color: 'purple'}}>Schedule</div>
            </Button>
            <Button onClick={() => executeScroll('pay')} color="inherit">
              <div style={{color: 'purple'}}>Pay</div>
            </Button>
            <Button onClick={() => executeScroll('resources')} color="inherit">
              <div style={{color: 'purple'}}>Resources</div>
            </Button>
            <Button onClick={() => executeScroll('props')} color="inherit">
              <div style={{color: 'purple'}}>Props</div>
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}