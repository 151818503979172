import * as i0 from './gallery/0.jpg';
import * as i1 from './gallery/1.jpg';
import * as i2 from './gallery/2.jpg';
import * as i3 from './gallery/3.jpg';
import * as i4 from './gallery/4.jpg';
import * as i5 from './gallery/5.jpg';
import * as i6 from './gallery/6.jpg';
import * as i7 from './gallery/7.jpg';
import * as i8 from './gallery/8.jpg';
import * as i9 from './gallery/9.jpg';
import * as i10 from './gallery/10.jpg';
import * as i11 from './gallery/11.jpg';
import * as i12 from './gallery/12.jpg';
import * as i13 from './gallery/13.jpg';
import * as i14 from './gallery/14.jpg';
import * as i15 from './gallery/15.jpg';
import * as i16 from './gallery/16.jpg';
import * as i17 from './gallery/17.jpg';


 
 const tileData = [
    {img: i0, title: 'Image', author: 'author', cols: 2, },
    {img: i4, title: 'Image', author: 'author', cols: 2, },
    {img: i17, title: 'Image', author: 'author', cols: 4, },
    {img: i14, title: 'Image', author: 'author', cols: 2, },
    {img: i5, title: 'Image', author: 'author', cols: 2, },
    {img: i6, title: 'Image', author: 'author', cols: 4, },
    {img: i7, title: 'Image', author: 'author', cols: 4, },
    {img: i8, title: 'Image', author: 'author', cols: 2, },
    {img: i16, title: 'Image', author: 'author', cols: 2, },
    {img: i9, title: 'Image', author: 'author', cols: 4, },
    {img: i10, title: 'Image', author: 'author', cols: 4, },
    {img: i11, title: 'Image', author: 'author', cols: 2, },
    {img: i12, title: 'Image', author: 'author', cols: 2, },
    {img: i13, title: 'Image', author: 'author', cols: 4, },
    {img: i15, title: 'Image', author: 'author', cols: 2, },
    {img: i1, title: 'Image', author: 'author', cols: 2, },
  ];

  export default tileData