import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import firebase from "./firebase.js";
import Button from '@material-ui/core/Button';
import PostModal from "./PostModal"
import * as logo from "./Picture1.png"
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    borderRadius: 10,
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    
    color: theme.palette.primary,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0.1) 100%)',
  },
}));

function getModalStyle() {
    const top = "50";
    const left = "50";
  
    return {
    position: "absolute",
      top: `${top}%`,
      left: `${left}%`,
      width: "90%",
      transform: `translate(-${top}%, -${left}%)`,
      background: "#1a1a1a",
      color: "white",
      padding: 40,
      borderRadius: 5,

    };
  }

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
var body;
var title;
var date;


const tileData = [
  {
    img: "https://d3re0f381bckq9.cloudfront.net/40670314_img-20200122-wa0095_554x554.jpg",
    title: "1 Mat",
  },
  {
    img: "https://i5.walmartimages.com/asr/909b2044-58ba-47ad-a191-57ad696f1443.173457eff7555629bc1d5e1cd5b3b08e.jpeg?odnWidth=612&odnHeight=612&odnBg=ffffff",
    title: "2 Blocks",
  },
  {
    img: "https://cdn.shopify.com/s/files/1/1728/2157/products/602-1206_Purple_A.jpg?v=1588348246",
    title: "1 Strap",
  },
]

const tileData2 = [
  {
    img: "https://www.areapropsnyc.com/uploads/5/7/3/7/57377719/s806770325102305418_p968_i4_w2560.jpeg",
    title: "3 Blankets ",
  },
  {
    img: "https://images-na.ssl-images-amazon.com/images/I/51M7aZAFmRL._AC_SL1000_.jpg",
    title: "1 Chair (ideally backless yoga chair)",
  },
  {
    img: "https://www.yogadirect.com/assets/images/Purple.jpg",
    title: "1 Bolster",
  },
]
  
export default function SingleLineGridList() {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = (title1, date1, description, author, image) => {
      console.log(date);
      //description.split("\n").forEach

      body = description.split("\n");
      title = title1;
        date = date1;
    // body = ( <div style={modalStyle} className={classes.paper}>
    //         <img style={{ boxShadow: '0 0 3px #fff',borderRadius: 3, position: "absolute" , right: "5%", top: "5%", height: "60px", zIndex: 1}} src={logo} alt="" />
    //         <h1 id="simple-modal-title" style={{ marginTop: 70}}>{title}</h1>
    //         <h4 id="simple-modal-title">{author} - {date}</h4>
            
    //         {description.split("\n").map((string) => (
    //             <p id="simple-modal-description">
    //             {string}
    //             </p>
    //         ))}
    //     </div>);
    setOpen(true);
    
  };

  


   
  



  return (
      <div>
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={3}>
        {tileData.map((tile) => (
          <GridListTile key={tile.img} style={{height: 300}}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar
              title={tile.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              // actionIcon={
              //   <Button onClick={() => handleOpen(tile.title, tile.date, tile.description, tile.author, tile.img)} size="small" variant="contained" color="primary">
              //         View
              //   </Button>
              // }
            />
          </GridListTile>
        ))}
      </GridList>
      <GridList className={classes.gridList} cols={3}>
        {tileData2.map((tile) => (
          <GridListTile key={tile.img} style={{height: 300}}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar
              title={tile.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              // actionIcon={
              //   <Button onClick={() => handleOpen(tile.title, tile.date, tile.description, tile.author, tile.img)} size="small" variant="contained" color="primary">
              //         View
              //   </Button>
              // }
            />
          </GridListTile>
        ))}
      </GridList>
      
    </div>
    <PostModal title={title} date={date} body={body} open={open} setOpen={setOpen}/>
    </div>
  );
}